import { createApp, h } from "vue"
import singleSpaVue from "single-spa-vue"
import FeatherIcon from 'vue-feather'
import Toast, { TYPE } from "vue-toastification"
import "vue-toastification/dist/index.css"
import App from './App.vue'
import router from './router'
import '@/scss/core.scss'
import '@/scss/libs/toastification.scss'
import './config-hfs'


const options = {
  toastDefaults: {
    [TYPE.DEFAULT]: {
      timeout: 5000,
      hideProgressBar: true,
      icon: false,
      closeButton: false,
    },
  },
}
const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App)
    },
  },
  handleInstance: app => {
    app.use(router)
    app.use(Toast, options)
    app.component('FeatherIcon', FeatherIcon)
  },
})

export const { bootstrap, mount, unmount } = vueLifecycles
