<template>
  <div id="app">
    <router-view key="panelRouterView" />
  </div>
</template>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;1,400&display=swap");
@import '@/scss/general/app.scss';

</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
  },
})
</script>
